import { ref, Ref, computed, ComputedRef } from 'vue'
import { getProjectBudget } from '@/service/operate/budgetReport'
import {
  IBudgetParams,
  IProjectBudget
} from '@/service/operate/budgetReport/type'

export default function useProjectBudget(params: Ref<IBudgetParams>): [
  ComputedRef<
    {
      num: number
      desc: string
    }[]
  >,
  ComputedRef<{
    pieData: {
      name: any
      value: any
    }[]
    unit: string
  }>,
  () => Promise<void>
] {
  const projectBudget = ref<IProjectBudget>({
    totalYsje: 0,
    totalYssyje: 0,
    projectBudgetList: []
  })

  // 查询时间范围内停机详情
  const getProjectBudgetAsync = async () => {
    const res = await getProjectBudget(params.value)
    if (res.data) {
      projectBudget.value = res.data
    }
  }
  const projectBudgetTotal = computed(() => {
    return [
      {
        num: projectBudget.value.totalYsje,
        desc: '总预算金额/元'
      },
      {
        num: projectBudget.value.totalYssyje,
        desc: '总预算剩余/元'
      }
    ]
  })

  const projectBudgetData = computed(() => {
    return {
      pieData: projectBudget.value.projectBudgetList
        .map((item) => {
          return {
            name: item.zxfymc,
            value: item.ysje
          }
        })
        .filter((item) => item.value),
      unit: '元',
      customTooltip: true,
      tooltipFormatter: (params: any) => {
        return `
          <div >
            <div class="flex align-start">
              <span class="mt-1 mr-1">${params.marker}</span>
              <div>
                <span style="font-size: 12px;">${params.name}</span>
                <div>
                 <span style="font-size: 12px;">${params.value}元</span>
                </div>
              </div>
            </div>
          </div>
          `
      }
    }
  })

  return [projectBudgetTotal, projectBudgetData, getProjectBudgetAsync]
}
