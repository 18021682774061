import {
  ITotalData,
  ITotalParams
} from '@/service/operate/energyManagement/type';
import { ref, Ref, computed, ComputedRef } from 'vue';
import { getElectricMeterStatistics } from '@/service/operate/energyManagement';

export default function useTotalData(params: Ref<ITotalParams>): [
  Ref<ITotalData>,
  ComputedRef<
    {
      num: number;
      desc: string;
    }[]
  >,
  () => Promise<void>
] {
  const totalData = ref<ITotalData>({
    areaElectricMeterList: [],
    total_electric_fee: 0,
    total_electric_value: 0
  });

  // 获取能源统计和各耗电区域对比数据
  const getElectricTotalAsync = async () => {
    const res = await getElectricMeterStatistics(params.value);
    totalData.value = res.data;
  };

  const summaryData = computed(() => {
    return [
      { num: totalData.value.total_electric_value, desc: '总耗电量/度' }
      // { num: totalData.value.total_electric_fee, desc: '电费/元' }
    ];
  });

  return [totalData, summaryData, getElectricTotalAsync];
}
