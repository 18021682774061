import { ref, Ref, computed, ComputedRef } from 'vue'
import { TShowBlueSkyBudget } from '@/service/operate/comprehensiveDaily/type'
import { canShowBlueSkyBudget } from '@/service/operate/comprehensiveDaily'

export default function useCanShowBlueSkyBudget(): [
  Ref<TShowBlueSkyBudget>,
  () => Promise<void>
] {
  const showBlueSkyBudget = ref<TShowBlueSkyBudget>('0')

  // 查询时间范围内停机详情
  const canShowBlueSkyBudgetAsync = async () => {
    const res = await canShowBlueSkyBudget()
    showBlueSkyBudget.value = res.data
  }

  return [showBlueSkyBudget, canShowBlueSkyBudgetAsync]
}
