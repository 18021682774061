import { ref, Ref } from 'vue';
import {
  getElectricPriceRule,
  getElectricBasicsPrice
} from '@/service/operate/energyManagement';
import {
  ITotalParams,
  IElectricBasicPrice,
  IElectricPriceItem
} from '@/service/operate/energyManagement/type';

export default function useElectricPrice(
  params: Ref<ITotalParams>
): [
  Ref<IElectricPriceItem[]>,
  Ref<IElectricBasicPrice>,
  () => Promise<void>,
  () => Promise<void>
] {
  const priceRule = ref<IElectricPriceItem[]>([]);
  const basePrice = ref<IElectricBasicPrice>({
    electric_capacity: 0,
    electric_price: 0,
    tot_electirc_price: 0
  });
  // 获取主机汇总数据
  const getElectricPriceRuleAsync = async () => {
    const res = await getElectricPriceRule({
      date: params.value.date
    });
    priceRule.value = res.data.list;
  };
  const getElectricBasicsPriceAsync = async () => {
    const res = await getElectricBasicsPrice();
    basePrice.value = res.data;
  };

  return [
    priceRule,
    basePrice,
    getElectricPriceRuleAsync,
    getElectricBasicsPriceAsync
  ];
}
