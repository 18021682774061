import hyRequest from '@/service'
import { IRequestResult } from '@/service/types'
import {
  ITotalData,
  ITotalParams,
  ITrendParams,
  ITrendDataItem,
  IElectricPriceItem,
  IElectricBasicPrice,
  IElectricPriceParams
} from './type'

enum DashboardApi {
  getElectricMeterStatistics = '/electricMeter/electricMeterStatistics',
  getElectricMeterTrend = '/electricMeter/electricMeterTrend',
  getElectricPriceRule = '/electricMeter/electricPriceRule',
  getElectricBasicsPrice = '/electricMeter/electricBasicsPrice'
}

// 耗电量统计及占比
export function getElectricMeterStatistics(
  params: ITotalParams
): Promise<IRequestResult<ITotalData>> {
  return hyRequest.post<IRequestResult<ITotalData>>({
    url: DashboardApi.getElectricMeterStatistics,
    data: params
  })
}
//耗电量趋势图
export function getElectricMeterTrend(
  params: ITrendParams
): Promise<IRequestResult<{ list: ITrendDataItem[] }>> {
  return hyRequest.post<IRequestResult<{ list: ITrendDataItem[] }>>({
    url: DashboardApi.getElectricMeterTrend,
    data: params
  })
}
export function getElectricPriceRule(
  params: IElectricPriceParams
): Promise<IRequestResult<{ list: IElectricPriceItem[] }>> {
  return hyRequest.post<IRequestResult<{ list: IElectricPriceItem[] }>>({
    url: DashboardApi.getElectricPriceRule,
    data: params
  })
}
export function getElectricBasicsPrice(): Promise<
  IRequestResult<IElectricBasicPrice>
> {
  return hyRequest.post<IRequestResult<IElectricBasicPrice>>({
    url: DashboardApi.getElectricBasicsPrice
  })
}
