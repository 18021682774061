import { ref, ComputedRef, computed } from 'vue'
import { getMoneyEditPermission } from '@/service/operate/comprehensiveDaily/index'
import { EPermission } from '@/service/operate/comprehensiveDaily/type'

export default function useMoneyEditPermission(): [
  ComputedRef<boolean>,
  () => Promise<void>
] {
  const permissionTag = ref(EPermission.no)
  const getMoneyEditPermissionAsync = async () => {
    const res = await getMoneyEditPermission()
    permissionTag.value = res.data
  }

  const hasPermission = computed(() => {
    return !!parseInt(permissionTag.value)
  })
  return [hasPermission, getMoneyEditPermissionAsync]
}
