import { ref, Ref, computed, ComputedRef } from 'vue'
import {
  IReceiveSumary,
  IAttendanceParams
} from '@/service/operate/comprehensiveDaily/type'
import { getReceiveSummary } from '@/service/operate/comprehensiveDaily'

export default function useReceiveSummary(params: Ref<IAttendanceParams>): [
  ComputedRef<
    {
      money: number | null
      amount: number | null
      desc: string
    }[]
  >,
  () => Promise<void>
] {
  const receiveSummary = ref<IReceiveSumary>({
    aggregateReleaseNums: 0,
    commercialTaxMoney: 0,
    mortarTaxMoney: 0,
    commercialReleaseNums: 0,
    mortarReleaseNums: 0,
    aggregateTaxMoney: 0
  })

  // 查询时间范围内停机详情
  const getReceiveSummaryAsync = async () => {
    const res = await getReceiveSummary(params.value)
    receiveSummary.value = res.data
  }

  //领用数据
  const receiveData = computed(() => {
    return [
      {
        money: receiveSummary.value.aggregateTaxMoney,
        amount: receiveSummary.value.aggregateReleaseNums,
        desc: '骨料'
      },
      {
        money: receiveSummary.value.commercialTaxMoney,
        amount: receiveSummary.value.commercialReleaseNums,
        desc: '商砼'
      },
      {
        money: receiveSummary.value.mortarTaxMoney,
        amount: receiveSummary.value.mortarReleaseNums,
        desc: '砂浆'
      }
    ]
  })

  return [receiveData, getReceiveSummaryAsync]
}
