import { hyRequest3 } from '@/service'
import { IRequestResult2 } from '@/service/types'
import {
  IBudgetParams,
  IBudgetSurvey,
  ICostBudget,
  ILogisticsBudget,
  IProjectBudget,
  ISaleAdvanceReceipt
} from './type'

enum DashboardApi {
  getBudgetSurvey = '/budget/budgetSurvey',
  getCostBudget = '/budget/costBudget',
  getProjectBudget = '/budget/projectBudget',
  getLogisticsBudget = '/budget/logisticsBudget',
  getSaleAdvanceReceipt = '/budget/saleAdvanceReceipt'
}

//查询月份内的预算概况
export function getBudgetSurvey(
  params: IBudgetParams
): Promise<IRequestResult2<IBudgetSurvey>> {
  return hyRequest3.get<IRequestResult2<IBudgetSurvey>>({
    url: DashboardApi.getBudgetSurvey,
    params
  })
}
//费用预算
export function getCostBudget(
  params: IBudgetParams
): Promise<IRequestResult2<ICostBudget>> {
  return hyRequest3.get<IRequestResult2<ICostBudget>>({
    url: DashboardApi.getCostBudget,
    params
  })
}
//专项报表
export function getProjectBudget(
  params: IBudgetParams
): Promise<IRequestResult2<IProjectBudget>> {
  return hyRequest3.get<IRequestResult2<IProjectBudget>>({
    url: DashboardApi.getProjectBudget,
    params
  })
}
//物流预算
export function getLogisticsBudget(
  params: IBudgetParams
): Promise<IRequestResult2<ILogisticsBudget>> {
  return hyRequest3.get<IRequestResult2<ILogisticsBudget>>({
    url: DashboardApi.getLogisticsBudget,
    params
  })
}
//销售预收
export function getSaleAdvanceReceipt(
  params: IBudgetParams
): Promise<IRequestResult2<ISaleAdvanceReceipt>> {
  return hyRequest3.get<IRequestResult2<ISaleAdvanceReceipt>>({
    url: DashboardApi.getSaleAdvanceReceipt,
    params
  })
}
