import { ref, Ref, computed, ComputedRef } from 'vue'
import { getCostBudget } from '@/service/operate/budgetReport'
import { IBudgetParams, ICostBudget } from '@/service/operate/budgetReport/type'

export default function useCostBudget(params: Ref<IBudgetParams>): [
  ComputedRef<
    {
      num: number
      desc: string
    }[]
  >,
  ComputedRef<{
    xLabels: string[]
    lineData: number[][]
    legendData: string[]
    colors: string[]
    grid: {
      left: number
      top: number
      bottom: number
      right: number
    }
  }>,
  () => Promise<void>
] {
  const costBudget = ref<ICostBudget>({
    totalYsje: 0,
    totalYssyje: 0,
    costBudgetListByDept: []
  })

  // 查询时间范围内停机详情
  const getCostBudgetAsync = async () => {
    const res = await getCostBudget(params.value)
    if (res.data) {
      costBudget.value = res.data
    }
  }
  const costBudgetTotal = computed(() => {
    return [
      {
        num: costBudget.value.totalYsje,
        desc: '总预算金额/元'
      },
      {
        num: costBudget.value.totalYssyje,
        desc: '总预算剩余/元'
      }
    ]
  })

  const costBudgetData = computed(() => {
    const lineData1: number[] = costBudget.value.costBudgetListByDept.map(
      (item) => item.ysje
    )
    const lineData2: number[] = costBudget.value.costBudgetListByDept.map(
      (item) => item.yssyje
    )

    const xLabels: string[] = costBudget.value.costBudgetListByDept.map(
      (item) => item.departmentName
    )
    const legendData = ['预算金额', '剩余预算']
    return {
      xLabels,
      lineData: [lineData1, lineData2],
      legendData,
      colors: ['#82A3FF', '#5AC57F'],
      grid: {
        left: 70,
        top: 40,
        bottom: 30,
        right: 20
      },
      customTooltip: true,
      tooptipFormatter: (
        params1: {
          axisValue: string
          marker: string
          seriesName: string
          value: string
        }[]
      ) => {
        let res = `
                <div >
                  <div class="flex align-center">
                    <span class="ml-2">${params1[0].axisValue}</span>

                  </div>
                `
        params1.forEach((item) => {
          res += `
                  <div >
                    ${item.marker}
                    <span style="font-size: 12px;">${item.seriesName}</span>
                    <span style="font-weight:bold;font-size: 12px;">${item.value}元</span>
                  </div>
                `
        })
        res += '</div>'
        return res
      }
    }
  })

  return [costBudgetTotal, costBudgetData, getCostBudgetAsync]
}
