import "vant/es/toast/style";
import _Toast from "vant/es/toast";
import { computed, defineComponent, ref } from 'vue';
import dayjs from 'dayjs';
export default defineComponent({
  name: 'CDateSelect',
  emits: ['confirm-date'],
  props: {
    backgroundColor: {
      type: String,
      default: '#F0F0F0'
    }
  },
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    var formatDate = function formatDate(date) {
      return dayjs(date).format('YYYY/MM/DD');
    };
    var date = ref(dayjs().format('YYYY/MM/DD'));
    var showDate = computed(function () {
      return dayjs(date.value).format('YYYY年MM月');
    });
    var maxDate = ref(new Date());
    var minDate = ref(new Date(dayjs().subtract(7, 'year').format('YYYY/MM/DD')));
    var preClick = function preClick() {
      if (date.value <= formatDate(minDate.value)) {
        _Toast('已经到可查询日期下限');
        return;
      }
      date.value = dayjs(date.value).subtract(1, 'month').format('YYYY/MM/DD');
      emit('confirm-date', date.value);
    };
    var nextClick = function nextClick() {
      if (date.value >= formatDate(maxDate.value)) {
        _Toast('不能查询未来日期');
        return;
      }
      date.value = dayjs(date.value).add(1, 'month').format('YYYY/MM/DD');
      emit('confirm-date', date.value);
    };
    return {
      date: date,
      showDate: showDate,
      preClick: preClick,
      nextClick: nextClick,
      maxDate: maxDate,
      minDate: minDate
    };
  }
});