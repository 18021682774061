import { ref, Ref, computed, ComputedRef } from 'vue'
import {
  IAttendanceParams,
  IAttendance
} from '@/service/operate/comprehensiveDaily/type'
import { getAttendanceStatistics } from '@/service/operate/comprehensiveDaily'

export default function useAttendance(
  attendanceParams: Ref<IAttendanceParams>
): [
  ComputedRef<number>,
  ComputedRef<
    {
      num: number
      desc: string
    }[]
  >,
  () => Promise<void>,
  ComputedRef<{
    pieData: {
      name: string
      value: number
    }[]
    unit: string
    customTooltip: boolean
    tooltipFormatter: (params: any) => string
    height: string
    radius: number[]
  }>
] {
  const attendanceTotal = ref<IAttendance>({
    no_sign_count: 0,
    leave_count: 0,
    leave_days_count: 0,
    leave_early_count: 0,
    require_count: 0,
    absent_count: 0,
    late_count: 0,
    attendance_count: 0,
    absent_from_work: 0
  })

  // 查询时间范围内停机详情
  const getAttendanceAsync = async () => {
    const res = await getAttendanceStatistics(attendanceParams.value)
    attendanceTotal.value = res.data
  }
  const attendanceData = computed(() => {
    return [
      // {
      //   num: attendanceTotal.value.require_count,
      //   desc: '总考勤人数'
      // },
      {
        num: attendanceTotal.value.attendance_count,
        desc: '已打卡人数'
      },
      {
        num: attendanceTotal.value.absent_count,
        desc: '未打卡人数'
      },
      {
        num: attendanceTotal.value.late_count,
        desc: '迟到'
      },
      {
        num: attendanceTotal.value.leave_early_count,
        desc: '早退'
      },
      {
        num: attendanceTotal.value.leave_count,
        desc: '请假'
      }
    ]
  })
  const require_count = computed(() => attendanceTotal.value.require_count)

  const attendaceEchartData = computed(() => {
    return {
      pieData: attendanceData.value
        .map((item) => {
          return {
            name: item.desc,
            value: item.num
          }
        })
        .sort((a, b) => b.value - a.value),
      unit: '人',
      customTooltip: true,
      tooltipFormatter: (params: any) => {
        console.log('params', params)
        return `
          <div >
            <div class="flex align-start">
              <span class="mt-1 mr-1">${params.marker}</span>
              <div>
                <span style="font-size: 12px;">${params.name}</span>
                <div>
                 <span style="font-size: 12px;">${params.value}人</span>
                </div>
              </div>
            </div>
          </div>
          `
      },
      height: '100%',
      radius: [60, 80],
      labelShow: false
    }
  })
  return [
    require_count,
    attendanceData,
    getAttendanceAsync,
    attendaceEchartData
  ]
}
