import { ref, Ref } from 'vue'
import {
  ILineStatusItem,
  ILineTimeItem
} from '@/service/operate/comprehensiveDaily/type'
import {
  getLineStatus,
  getLineTimes
} from '@/service/operate/comprehensiveDaily'

export default function useLineStatus(): [
  Ref<ILineTimeItem[]>,
  () => Promise<void>
] {
  const lineStatusList = ref<ILineTimeItem[]>([])

  // 查询时间范围内停机详情
  const getLineStatusAsync = async () => {
    const res = await getLineTimes()
    lineStatusList.value = res.data.list
  }

  return [lineStatusList, getLineStatusAsync]
}
