import { ref, Ref, computed, ComputedRef } from 'vue'
import { getLogisticsBudget } from '@/service/operate/budgetReport'
import {
  IBudgetParams,
  ILogisticsBudget
} from '@/service/operate/budgetReport/type'

export default function useLogisticsBudget(params: Ref<IBudgetParams>): [
  ComputedRef<
    {
      num: number
      desc: string
    }[]
  >,
  ComputedRef<{
    pieData: {
      name: any
      value: any
    }[]
    unit: string
  }>,
  () => Promise<void>
] {
  const logisticsBudget = ref<ILogisticsBudget>({
    totalYjcgsl: 0,
    totalSjrksl: 0,
    logisticsBudgetList: []
  })

  // 查询时间范围内停机详情
  const getLogisticsBudgetAsync = async () => {
    const res = await getLogisticsBudget(params.value)
    if (res.data) {
      logisticsBudget.value = res.data
    }
  }
  const logisticsBudgetTotal = computed(() => {
    return [
      {
        num: logisticsBudget.value.totalYjcgsl,
        desc: '预计采购数量/条'
      },
      {
        num: logisticsBudget.value.totalSjrksl,
        desc: '实际入库数量/条'
      }
    ]
  })

  const logisticsBudgetData = computed(() => {
    return {
      pieData: logisticsBudget.value.logisticsBudgetList
        .map((item) => {
          return {
            name: item.departmentname,
            value: item.cgzje
          }
        })
        .filter((item) => item.value),
      unit: '元',
      customTooltip: true,
      tooltipFormatter: (params: any) => {
        return `
          <div >
            <div class="flex align-start">
              <span class="mt-1 mr-1">${params.marker}</span>
              <div>
                <span style="font-size: 12px;">${params.name}</span>
                <div>
                 <span style="font-size: 12px;">${params.value}元</span>
                </div>
              </div>
            </div>
          </div>
          `
      }
    }
  })

  return [logisticsBudgetTotal, logisticsBudgetData, getLogisticsBudgetAsync]
}
