import { ref, Ref } from 'vue'
import { getBudgetSurvey } from '@/service/operate/budgetReport'
import {
  IBudgetParams,
  IBudgetSurvey
} from '@/service/operate/budgetReport/type'

export default function useBudgetSurvey(
  params: Ref<IBudgetParams>
): [Ref<IBudgetSurvey>, () => Promise<void>] {
  const budgetSurvey = ref<IBudgetSurvey>({
    payImplementRate: 0,
    receiptImplementRate: 0,
    totalActualPay: 0,
    totalActualReceipt: '',
    totalBudgetPay: 0,
    totalBudgetReceipt: 0
  })

  // 查询时间范围内停机详情
  const getBudgetSurveyAsync = async () => {
    const res = await getBudgetSurvey(params.value)
    budgetSurvey.value = res.data
  }

  return [budgetSurvey, getBudgetSurveyAsync]
}
