import { ref, Ref, ComputedRef, computed } from 'vue'
import {
  ISaleSummary,
  IAttendanceParams,
  IPurchaseSumary
} from '@/service/operate/comprehensiveDaily/type'
import {
  getSaleSummary,
  getPurchaseSummary,
  getAggregateSale,
  getCommercialSale,
  getMortarSale
} from '@/service/operate/comprehensiveDaily'

export default function useSaleSummary(params: Ref<IAttendanceParams>): [
  Ref<ISaleSummary>,
  ComputedRef<
    {
      money: number
      amount: number
      totalSingular: number
      totalAveragePrice: number
      desc: string
      routeName: string
    }[]
  >,
  ComputedRef<
    {
      title: string
      routeName: string
      children: {
        desc: string
        value: number
      }[]
    }[]
  >,
  () => Promise<void>,
  () => void,
  () => Promise<void>
] {
  const saleSummary = ref<ISaleSummary>({
    aggregateTotalMoney: 0, // 骨料总金额（万元）
    aggregateTotalMoneyYuan: 0, // 骨料总金额（元
    aggregateTotalSettleValue: 0, // 骨料进料量（吨）
    commercialTotalMoney: 0, // 商砼总金额（万元）
    commercialTotalMoneyYuan: 0, // 商砼总金额（元）
    commercialTotalSettleValue: 0, // 商砼进料量（吨）
    mortarTotalMoney: 0, // 砂浆总金额（万元）
    mortarTotalMoneyYuan: 0, // 砂浆总金额（元）
    mortarTotalSettleValue: 0 // 砂浆进料量（吨）
  })

  const purchaseSummary = ref<IPurchaseSumary>({
    mortarTotalSingular: 0, // 砂浆单数
    mortarTotalAveragePrice: 0, // 砂浆均价（元/吨）
    aggregateTotalSingular: 0, // 骨料单数
    aggregateTotalSettleValue: 0, // 骨料进料量（吨）
    aggregateTotalAveragePrice: 0, // 骨料均价（元/吨）
    commercialTotalSingular: 0, // 商砼单数
    aggregateTotalMoney: 0, // 骨料总金额（万元）
    commercialTotalMoney: 0, // 商砼总金额（万元）
    commercialTotalAveragePrice: 0, // 商砼单价（元/吨）
    commercialTotalSettleValue: 0, // 商砼进料量（吨）
    mortarTotalMoney: 0, // 砂浆总金额（万元）
    mortarTotalSettleValue: 0, // 砂浆进料量（吨）
    aggregateTotalMoneyYuan: 0, // 骨料总金额（元）
    commercialTotalMoneyYuan: 0, // 商砼总金额（元）
    mortarTotalMoneyYuan: 0 // 砂浆总金额（元）
  })
  const saleSummary1 = ref({
    aggregateTotalAveragePrice: 0, // 骨料均价（元/吨）
    aggregateTotalSingular: 0, // 骨料单数
    commercialTotalAveragePrice: 0, // 商砼单价（元/吨）
    commercialTotalSingular: 0, // 商砼单数
    mortarTotalAveragePrice: 0, // 砂浆均价（元/吨）
    mortarTotalSingular: 0 // 砂浆单数
  })

  // 查询时间范围内停机详情
  const getSaleSummaryAsync = async () => {
    const res = await getSaleSummary(params.value)
    saleSummary.value = res.data
  }

  // 查询采购详情数据
  const getPurchaseSummaryAsync = async () => {
    const res = await getPurchaseSummary(params.value)
    purchaseSummary.value = res.data
  }
  // 销量数据-骨料
  const getAggregateSaleAsync = async () => {
    const res = await getAggregateSale(
      Object.assign({}, params.value, { type: '5' })
    )
    saleSummary1.value.aggregateTotalAveragePrice = res.data.totalAveragePrice
    saleSummary1.value.aggregateTotalSingular = res.data.totalSingular
    console.log(
      666,
      res.data.totalSingular,
      saleSummary1.value.aggregateTotalSingular
    )
  }
  // 销量数据-商砼
  const getCommercialSaleAsync = async () => {
    const res = await getCommercialSale(
      Object.assign({}, params.value, { type: '0' })
    )
    saleSummary1.value.commercialTotalAveragePrice = res.data.totalAveragePrice
    saleSummary1.value.commercialTotalSingular = res.data.totalSingular
  }
  // 销量数据-砂浆
  const getMortarSaleAsync = async () => {
    const res = await getMortarSale(
      Object.assign({}, params.value, { type: '0' })
    )
    saleSummary1.value.mortarTotalAveragePrice = res.data.totalAveragePrice
    saleSummary1.value.mortarTotalSingular = res.data.totalSingular
  }

  const getSaleSummaryAsync1 = () => {
    getAggregateSaleAsync()
    getCommercialSaleAsync()
    getMortarSaleAsync()
  }

  //销量数据
  const saleData = computed(() => {
    return [
      {
        money: saleSummary.value.aggregateTotalMoney,
        amount: saleSummary.value.aggregateTotalSettleValue,
        totalSingular: saleSummary1.value.aggregateTotalSingular,
        totalAveragePrice: saleSummary1.value.aggregateTotalAveragePrice,
        desc: '骨料',
        routeName: 'SalesAggregateDailyReport'
      },
      {
        money: saleSummary.value.mortarTotalMoney,
        amount: saleSummary.value.mortarTotalSettleValue,
        totalSingular: saleSummary1.value.mortarTotalSingular,
        totalAveragePrice: saleSummary1.value.mortarTotalAveragePrice,
        desc: '砂浆',
        routeName: 'SaleMortarDailyReport'
      },
      {
        money: saleSummary.value.commercialTotalMoney,
        amount: saleSummary.value.commercialTotalSettleValue,
        totalSingular: saleSummary1.value.commercialTotalSingular,
        totalAveragePrice: saleSummary1.value.commercialTotalAveragePrice,
        desc: '商砼',
        routeName: 'SaleShangTongDailyReport'
      }
    ]
  })

  //采购数据
  const purchaseData = computed(() => {
    return [
      {
        title: '骨料',
        routeName: 'PurchaseAggregateDailyReport',
        children: [
          {
            desc: '总进料量/吨',
            value: purchaseSummary.value.aggregateTotalSettleValue
          },
          {
            desc: '总单数/单',
            value: purchaseSummary.value.aggregateTotalSingular
          },
          {
            desc: '金额/元',
            value: purchaseSummary.value.aggregateTotalMoneyYuan
          }
          // {
          //   desc: '均价/元',
          //   value: purchaseSummary.value.aggregateTotalAveragePrice
          // }
        ]
      },
      {
        title: '砂浆',
        routeName: 'PurchaseMortarDailyReport',
        children: [
          {
            desc: '总进料量/吨',
            value: purchaseSummary.value.mortarTotalSettleValue
          },
          {
            desc: '总车次/次',
            value: purchaseSummary.value.mortarTotalSingular
          },
          {
            desc: '金额/元',
            value: purchaseSummary.value.mortarTotalMoneyYuan
          }
          // {
          //   desc: '均价/元',
          //   value: purchaseSummary.value.mortarTotalAveragePrice
          // }
        ]
      },
      {
        title: '商砼',
        routeName: 'purchaseShangTongDailyReport',
        children: [
          {
            desc: '总进料量/吨',
            value: purchaseSummary.value.commercialTotalSettleValue
          },
          {
            desc: '总车次/次',
            value: purchaseSummary.value.commercialTotalSingular
          },
          {
            desc: '金额/元',
            value: purchaseSummary.value.commercialTotalMoneyYuan
          }
          // {
          //   desc: '均价/元',
          //   value: purchaseSummary.value.commercialTotalAveragePrice
          // }
        ]
      }
    ]
    // return [
    //   {
    //     money: purchaseSummary.value.aggregateTotalMoney,
    //     amount: purchaseSummary.value.aggregateTotalSettleValue,
    //     desc: '骨料',
    //     routeName: 'PurchaseAggregateDailyReport'
    //   },
    //   {
    //     money: purchaseSummary.value.commercialTotalMoney,
    //     amount: purchaseSummary.value.commercialTotalSettleValue,
    //     desc: '商砼',
    //     routeName: 'purchaseShangTongDailyReport'
    //   },
    //   {
    //     money: purchaseSummary.value.mortarTotalMoney,
    //     amount: purchaseSummary.value.mortarTotalSettleValue,
    //     desc: '砂浆',
    //     routeName: 'PurchaseMortarDailyReport'
    //   }
    // ]
  })

  return [
    saleSummary,
    saleData,
    purchaseData,
    getSaleSummaryAsync,
    getSaleSummaryAsync1,
    getPurchaseSummaryAsync
  ]
}
