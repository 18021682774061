// 产量统计
import { ref, Ref, computed } from 'vue'
import {
  IAttendanceParams,
  IProSumary,
  IProSumaryRes
} from '@/service/operate/comprehensiveDaily/type'
import { getProSum } from '@/service/operate/comprehensiveDaily'

export default function useProSummary(
  params: Ref<IAttendanceParams>
): [Ref<IProSumary[]>, () => Promise<void>] {
  const proSumaryRes = ref<IProSumaryRes>({
    mortarPro: 0, // 砂浆产量（吨）
    stonePro: 0, // 骨料产量（吨）
    commercialPro: 0 // 商砼产量（吨）
  })

  // 查询时间范围内停机详情
  const getProSumary = async () => {
    const res = await getProSum(params.value)
    proSumaryRes.value = res.data
  }

  const proSumary = computed(() => {
    return [
      {
        desc: '骨料',
        routeName: 'ProReportAggregate',
        value: proSumaryRes.value.stonePro
      },
      {
        desc: '砂浆',
        routeName: 'ProReportMortar',
        value: proSumaryRes.value.mortarPro
      },
      {
        desc: '商砼',
        routeName: 'ProReportShangTong',
        value: proSumaryRes.value.commercialPro
      }
    ]
  })

  return [proSumary, getProSumary]
}
