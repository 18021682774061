import hyRequest from '@/service'
import { IRequestResult } from '@/service/types'
import { IInspectBoardParams } from './type'

enum DashboardApi {
  getDeviceInspectBoardForPatrol = '/inspect/deviceInspectBoardForPatrol'
}

//查询月份内的预算概况
export function getDeviceInspectBoardForPatrol(
  params: IInspectBoardParams
): Promise<IRequestResult<any>> {
  return hyRequest.post<IRequestResult<any>>({
    url: DashboardApi.getDeviceInspectBoardForPatrol,
    data: params
  })
}

//查询巡检区域信息
export function getEqulistForPatrol(
  params: unknown
): Promise<IRequestResult<any>> {
  return hyRequest.post<IRequestResult<any>>({
    url: '/inspect/equlistForPatrol',
    data: params
  })
}

//查询巡检区域信息
export function getPointlistForEqu(
  params: unknown
): Promise<IRequestResult<any>> {
  return hyRequest.post<IRequestResult<any>>({
    url: '/inspect/pointlistForEqu',
    data: params
  })
}
