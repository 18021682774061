import {
  ITotalParams,
  ITrendDataItem
} from '@/service/operate/energyManagement/type';
import { ref, Ref, computed, ComputedRef } from 'vue';
import { getElectricMeterTrend } from '@/service/operate/energyManagement';

export default function useTrend(params: Ref<ITotalParams>): [
  ComputedRef<{
    xLabels: string[];
    lineData: number[][];
    legendData: string[];
    colors: string[];
    grid: {
      left: number;
      top: number;
      bottom: number;
      right: number;
    };
    legendBottom: number;
    customTooltip: boolean;
    tooptipFormatter: (
      params1: {
        axisValue: string;
        marker: string;
        seriesName: string;
        value: string;
      }[]
    ) => string;
  }>,
  ComputedRef<{
    xLabels: string[];
    lineData: number[][];
    legendData: string[];
    colors: string[];
    grid: {
      left: number;
      top: number;
      bottom: number;
      right: number;
    };
    legendBottom: number;
    customTooltip: boolean;
    tooptipFormatter: (
      params1: {
        axisValue: string;
        marker: string;
        seriesName: string;
        value: string;
      }[]
    ) => string;
  }>,
  () => Promise<void>,
  () => Promise<void>
] {
  const trendData = ref<ITrendDataItem[]>([]);
  const trendYearData = ref<ITrendDataItem[]>([]);
  // 获取主机汇总数据
  const getElectricMeterTrendAsync = async () => {
    let num = 30;
    switch (params.value.type) {
      case 0:
        num = 30;
        break;
      case 1:
        num = 30;
        break;
      case 2:
        num = 12;
        break;
      case 3:
        num = 3;
        break;
      case 4:
        num = 10;
        break;
      default:
        num = 30;
    }
    const res = await getElectricMeterTrend({
      ...params.value,
      rangeType: params.value.type === 0 ? 5 : params.value.type,
      rangeNum: num
    });
    trendData.value = res.data.list;
  };
  const getElectricMeterTrendYearAsync = async () => {
    const res = await getElectricMeterTrend({
      type: 3,
      date: params.value.date,
      rangeType: -1,
      rangeNum: 3
    });
    trendYearData.value = res.data.list;
  };
  const trendCountData = computed(() => {
    const lineData1 = trendData.value.map((item) => {
      return item.electric_value;
    });
    const lineData2 = trendData.value.map((item) => {
      return item.electric_fee;
    });

    const xLabels = trendData.value.map((item) => item.time);
    // const legendData = ['电量', '电费'];
    const legendData = ['电量'];

    return {
      xLabels,
      lineData: [lineData1, lineData2],
      legendData,
      colors: ['#82A3FF', '#5AC57F', '#EEC324'],
      grid: {
        left: 55,
        top: 10,
        bottom: 55,
        right: 20
      },
      legendBottom: 2,
      customTooltip: true,
      tooptipFormatter: (
        params1: {
          axisValue: string;
          marker: string;
          seriesName: string;
          value: string;
        }[]
      ) => {
        let res = `
                <div >
                  <div class="flex align-center">
                    <span class="ml-2">${params1[0].axisValue}</span>

                  </div>
                `;
        params1.forEach((item) => {
          res += `
                  <div >
                    ${item.marker}
                    <span style="font-size: 12px;">${item.seriesName}</span>
                    <span style="font-weight:bold;font-size: 12px;">${
                      item.value
                    }${item.seriesName === '电量' ? '度' : '元'}</span>
                  </div>
                `;
        });
        res += '</div>';
        return res;
      }
    };
  });
  const trendCountYearData = computed(() => {
    const lineData1 = trendYearData.value.map((item) => {
      return item.electric_value;
    });
    const lineData2 = trendYearData.value.map((item) => {
      return item.electric_fee;
    });

    const xLabels = trendYearData.value.map((item) => item.time);
    const legendData = ['电量', '电费'];

    return {
      xLabels,
      lineData: [lineData1, lineData2],
      legendData,
      colors: ['#82A3FF', '#5AC57F', '#EEC324'],
      grid: {
        left: 55,
        top: 10,
        bottom: 55,
        right: 20
      },
      legendBottom: 2,
      customTooltip: true,
      tooptipFormatter: (
        params1: {
          axisValue: string;
          marker: string;
          seriesName: string;
          value: string;
        }[]
      ) => {
        let res = `
                <div >
                  <div class="flex align-center">
                    <span class="ml-2">${params1[0].axisValue}</span>

                  </div>
                `;
        params1.forEach((item) => {
          res += `
                  <div >
                    ${item.marker}
                    <span style="font-size: 12px;">${item.seriesName}</span>
                    <span style="font-weight:bold;font-size: 12px;">${
                      item.value
                    }${item.seriesName === '电量' ? '度' : '元'}</span>
                  </div>
                `;
        });
        res += '</div>';
        return res;
      }
    };
  });

  return [
    trendCountData,
    trendCountYearData,
    getElectricMeterTrendAsync,
    getElectricMeterTrendYearAsync
  ];
}
