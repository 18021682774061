import { defineComponent, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { getPointlistForEqu } from '@/service/operate/dailyInspection';
export default defineComponent({
  name: 'DailyInspectionDetail',
  setup: function setup(props, ctx) {
    var detail = ref([]);
    var route = useRoute();
    onMounted(function () {
      console.log(route.query);
      getPointlistForEqu({
        equId: route.query.id,
        date: route.query.date,
        hasPatrol: route.query.state
      }).then(function (res) {
        console.log(res.data.list);
        detail.value = res.data.list;
      });
    });
    return {
      detail: detail
    };
  }
});