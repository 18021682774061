import { ref, Ref, computed, ComputedRef } from 'vue'
import { getSaleAdvanceReceipt } from '@/service/operate/budgetReport'
import {
  IBudgetParams,
  ISaleAdvanceReceipt
} from '@/service/operate/budgetReport/type'

export default function useSaleAdvanceReceipt(params: Ref<IBudgetParams>): [
  ComputedRef<
    {
      num: number
      desc: string
    }[]
  >,
  ComputedRef<{
    pieData: {
      name: any
      value: any
    }[]
    unit: string
  }>,
  () => Promise<void>
] {
  const saleAdvanceReceipt = ref<ISaleAdvanceReceipt>({
    sjyshj: 0,
    slyshj: 0,
    styshj: 0,
    yssrzhj: 0
  })

  const getSaleAdvanceReceiptAsync = async () => {
    const res = await getSaleAdvanceReceipt(params.value)
    saleAdvanceReceipt.value = res.data
  }
  const saleAdvanceReceiptTotal = computed(() => {
    return [
      {
        num: saleAdvanceReceipt.value.yssrzhj,
        desc: '销售预收总金额/元'
      }
    ]
  })

  const saleAdvanceReceiptData = computed(() => {
    return {
      pieData: [
        {
          name: '骨料',
          value: saleAdvanceReceipt.value.slyshj
        },
        {
          name: '商砼',
          value: saleAdvanceReceipt.value.styshj
        },
        {
          name: '砂浆',
          value: saleAdvanceReceipt.value.sjyshj
        }
      ].filter((item) => item.value),
      unit: '元',
      customTooltip: true,
      tooltipFormatter: (params: any) => {
        return `
          <div >
            <div class="flex align-start">
              <span class="mt-1 mr-1">${params.marker}</span>
              <div>
                <span style="font-size: 12px;">${params.name}</span>
                <div>
                 <span style="font-size: 12px;">${params.value}元</span>
                </div>
              </div>
            </div>
          </div>
          `
      }
    }
  })

  return [
    saleAdvanceReceiptTotal,
    saleAdvanceReceiptData,
    getSaleAdvanceReceiptAsync
  ]
}
